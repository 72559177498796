import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/customers",
    name: "customers",
    component: () => import("./components/Customers/CustomersList.vue"),
  },
  {
    path: "/customer/:id",
    name: "customer",
    component: () => import("./components/Customers/Customer.vue"),
  },
  {
    path: "/add-customer",
    name: "add-customer",
    component: () => import("./components/Customers/AddCustomer.vue"),
  },
  /////////////////////////////////
  {
    path: "/",
    alias: "/items",
    name: "items-list",
    component: () => import("./components/Items/ItemsList.vue"),
  },
  {
    path: "/item/:id",
    name: "item",
    component: () => import("./components/Items/Item.vue"),
  },
  {
    path: "/add-item",
    name: "add-item",
    component: () => import("./components/Items/AddItem.vue"),
  },
  //////////////////////////////////
  {
    path: "/cases",
    name: "cases",
    component: () => import("./components/Cases/CasesList.vue"),
  },
  {
    path: "/case/:id",
    name: "case",
    component: () => import("./components/Cases/Case.vue"),
  },
  {
    path: "/add-case",
    name: "add-case",
    component: () => import("./components/Cases/AddCase.vue"),
  },
  {
    path: "/caseitems/:id/items",
    name: "CaseItems",
    component: () => import("./components/Cases/CaseItems.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
