<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <router-link to="/" class="navbar-brand">Eykaluta App</router-link>
      <div class="navbar-nav mr-auto  border-left ml-5">
        <li class="nav-item">
          <router-link to="/customers" class="nav-link">Kundar</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/cases" class="nav-link">Kuffert</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/items" class="nav-link">Lutir</router-link>
        </li>
        <!-- <li class="nav-item border-left ml-5">
          <router-link to="/add-item" class="nav-link">Add Item</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/add-customer" class="nav-link"
            >Add Customer</router-link
          >
        </li> 

        <li class="nav-item">
          <router-link to="/add-case" class="nav-link">Add Case</router-link>
        </li>-->
      </div>
    </nav>

    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>
